#gallery-landing {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
    min-height: 600px;
    position: relative;
    overflow: hidden;
}
#gallery-landing h1 {
    text-align: center;
    font-size: 4rem;
    color: var(--main);
    filter: drop-shadow(3px 3px 0 var(--bg)) drop-shadow(0px 0px 20px var(--bg));
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 9;
}
#wood-background {
    pointer-events: none;
    position: absolute;
    background-size: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 1s;
}
#polaroid-wrapper {
    pointer-events: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 80px;
    margin-top: 60px;
}
#polaroid-wrapper:hover .polaroid:not(:hover), 
#polaroid-wrapper:hover + #wood-background {
    filter: blur(1px) brightness(0.5);
}
.polaroid {
    background: rgb(251,251,240);
    background: linear-gradient(180deg, rgba(251,251,240,1) 0%, rgba(233,231,216,1) 30%, rgba(236,236,229,1) 52%, rgba(230,230,218,1) 73%, rgba(223,223,215,1) 82%, rgba(247,246,237,1) 100%);
    padding: 20px;
    box-shadow: inset 0 0 2px #000a, 2px 4px 4px 1px #000a;
    filter: blur(0px);
    border-radius: 2px 1px 4px 3px;
    transition: 700ms ease;
    z-index: 1;
    visibility: hidden;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}
.polaroid:nth-child(1) {
    animation-name: drop-in-1;
    animation-delay: 0.5s;
}
.polaroid:nth-child(2) {
    animation-name: drop-in-2;
    animation-delay: 0.75s;
}
.polaroid:nth-child(3) {
    animation-name: drop-in-3;
    animation-delay: 1s;
}
.polaroid:hover {
    box-shadow: inset 0 0 2px #000a, 12px 14px 17px 5px #000a;
    filter: blur(0px);
    transform: rotateZ(0deg) scale(1.2) !important;
    z-index: 99;
}
.polaroid h2, .polaroid p {
    color: var(--bg);
    text-align: center;
}
.polaroid p {
    margin-top: -20px;
    margin-bottom: 0px;
}
.polaroid img {
    max-height: 212px;
    aspect-ratio: 3 / 2;
    object-fit: cover;
    /* filter: drop-shadow(0 0 3px #000a); */
}
.vignette::after {
    box-shadow: inset 0 0 3px 1px #00000080;
    content: '';
    display: block;
    height: 212px;
    position: absolute;
    top: 20px;
    width: 89%;
}
#back-to-gallery {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 10px;
}
#gallery-description {
    max-width: fit-content;
    margin: -5px auto 0 auto;
    padding: 10px 20px;
    color: #000;
    filter: brightness(1.4) saturate(0.5);
    transform: rotate(-1deg);
    box-shadow: 0 0 8px;
}
#gallery-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#gallery-carousel button {
    background-color: var(--main);
    color: var(--bg);
    border: none;
    font-size: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    height: 40px;
}
#gallery-carousel button:hover {
    filter: drop-shadow(0 0 10px white);
}
/* width */
#thumbnails-wrapper::-webkit-scrollbar {
    height: 10px;
}
/* Track */
#thumbnails-wrapper::-webkit-scrollbar-track {
    /* background: linear-gradient(0deg, var(--bg) 0%, var(--bg) 40%, var(--main) 40%, var(--main) 60%, var(--bg) 60%, var(--bg) 100%); */
    background: #909090;
}
/* Handle */
#thumbnails-wrapper::-webkit-scrollbar-thumb {
    background: var(--main);
}
/* Handle on hover */
#thumbnails-wrapper::-webkit-scrollbar-thumb:hover {
    background: white;
}
#thumbnails-wrapper {
    max-width: min(1200px, 80%);
    margin: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}
.thumbnail {
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
    background-color: var(--main);
    margin: 10.0px;
    /* position: relative; */
    box-shadow: 3px 3px 0;
    border: 1px solid;
    scroll-snap-align: start;
}
.thumbnail:hover {
    filter: brightness(1.4);
    animation-name: hovering;
    animation-duration: 2s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.active-thumb {
    filter: brightness(1.4);
}
.thumbnail img {
    width: 100%;
    height: 100%;
    max-height: 800px;
    object-fit: cover;
    pointer-events: none;
}
#gallery-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
}
#gallery-wrapper img {
    display: none;
    max-width: 100%;
    height: 60vh;
    animation: fade-in 1s;
    margin-bottom: 0px;
    object-fit: contain;
    aspect-ratio: 16/9;
}
#gallery-wrapper:hover #gallery-left, #gallery-wrapper:hover #gallery-right {
    display: flex;
}
#gallery-left, #gallery-right {
    display: none;
    background-color: var(--main);
    color: var(--bg);
    border: none;
    font-size: 50px;
    border-radius: 100%;
    align-items: center;
    height: 60px;
    z-index: 9;
}
#gallery-left:hover, #gallery-right:hover {
    filter: drop-shadow(0 0 10px white);
}
#gallery-right {
    margin-left: -30px;
}
#gallery-left {
    margin-right: -30px;
}
.active-gallery {
    display: block !important;
}
#scroll-hint {
    color: var(--main);
    z-index: 99;
    font-weight: 900;
    text-align: center;
    margin-top: -10px;

    animation-name: slight-fade-in;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier();
    animation-iteration-count: infinite;
}
#scroll-hint  svg {
    margin-bottom: -3px;
}
@media (max-width: 1180px) {
    #wood-background {
        background-size: contain;
    }
}

@media (max-width: 500px) {
    .thumbnail {
        min-width: 100px;
        max-height: 100px;
        min-height: 100px;
    }
    #gallery-left, #gallery-right {
        padding-top: 5px;
        font-size: 20px;
        height: 30px;
    }
    #gallery-left:hover, #gallery-right:hover {
        filter: drop-shadow(0 0 10px white);
    }
    #gallery-right {
        margin-left: -40px;
        display: block;
    }
    #gallery-left {
        margin-right: -40px;
        display: block;
    }
    #gallery-carousel button {
        display: none;
    }
    #thumbnails-wrapper {
        max-width: 100%;
    }
    #gallery-wrapper img {
        height: 50vh;
    }
}

@keyframes hovering {
    0% {box-shadow: 3px 3px 0;}
    50% {box-shadow: 5px 5px 0;}
    100% {box-shadow: 3px 3px 0;}
}
@keyframes fade-in {
    from { opacity: 0 }
    to{ opacity: 1 }
}
@keyframes slight-fade-in {
    from { opacity: 0.6 }
    to{ opacity: 1 }
}
@keyframes drop-in-1 {
    from {
        transform: scale(2) rotateZ(16deg) translateY(-21px) translateX(-400px);
        filter: blur(10px);
        visibility: visible;
    }
    to {
        transform: scale(1) rotateZ(-5deg) translateY(-2px) translateX(0px);
        filter: blur(0px);
        visibility: visible;
    }
}
@keyframes drop-in-2 {
    from {
        transform: scale(2) rotateZ(-16deg) translateY(621px);
        filter: blur(10px);
        visibility: visible;
    }
    to {
        transform: scale(1) rotateZ(2deg) translateY(12px);
        filter: blur(0px);
        visibility: visible;
    }
}
@keyframes drop-in-3 {
    from {
        transform: scale(2) rotateZ(16deg)translateY(211px) translateX(400px);
        filter: blur(10px);
        visibility: visible;
    }
    to {
        transform: scale(1) rotateZ(-4deg) translateX(-5px) translateX(-5px);
        filter: blur(0px);
        visibility: visible;
    }
}