#spotlight {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    max-width: 900px;
    min-height: 50vh;
    margin: 0 auto 80px auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--main);
    border-radius: 0 0 20px 20px;
    transition: 1s ease-in-out;
}
.spotlight-inner {
    display: none;
    align-items: center;
}
.spotlight-inner.active-spotlight {
    display: flex;
}
.name {
    font-size: 2rem;
    margin: 0;
}
.character {
    margin-top: 0;
}
.character span {
    font-size: 1rem;
}
.headshot, .bio {
    margin: 0 20px;
    animation: fade-in 1s;
    box-shadow: 3px 3px 9px 1px #0000004d;
}
.headshot {
    min-width: 280px;
    height: 350px;
    aspect-ratio: 4/5;
    background-color: var(--main);
    border: 10px solid white;
    transform: rotateZ(1deg);
}
.bio {
    padding: 20px;
    background-color: white;
    color: black;
    transform: rotateZ(359deg);
}
.bio p {
    font-size: 0.9rem;
    text-align: justify;
}
#cast-carousel {
    display: flex;
    max-width: 900px;
    height: 125px;
    margin: 40px auto 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: flex-end;
    padding: 0 10px;
    box-sizing: border-box;
}
.cast-thumb {
    background-color: var(--main);
    color: var(--bg);
    text-align: center;
    padding: 10px 30px 0 30px;
    border-radius: 5px 30px 0 0;
    -webkit-user-select: none;
    user-select: none;
    margin: 0 -10px;
    filter: drop-shadow(0 0 3px #000a);
    transform: rotateZ(360deg) translateY(10px);
    transition: 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
.cast-thumb:hover, .active {
    opacity: 1;
    z-index: 99 !important;
    transform: rotateZ(359deg) translateY(10px);
}
.cast-thumb:hover .thumb-character, .active .thumb-character{
    max-height: 40px;
    margin: 5px 0 20px 0;
    opacity: 1;
}
.cast-thumb:hover .thumb-name, .active .thumb-name {
    margin: 0px 0px 0px 0px;
}
.thumb-headshot {
    pointer-events: none;
    max-height: 0;
}
.thumb-name {
    font-size: 1.3rem;
    line-height: 1;
    margin: 0px 0px 20px 0px;
    pointer-events: none;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.thumb-character {
    white-space: pre-wrap;
    line-height: 1.1;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    pointer-events: none;
    max-height: 0;
    opacity: 0;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}
#handle-helper {
    pointer-events: none;
    position: absolute;
    left: 20px;
    top: -33px;
    color: white;
    z-index: 99;
    font-size: 14px;
    font-weight: 900;

    animation-name: breath;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-timing-function: cubic-bezier();
    animation-iteration-count: infinite;
}
#handle-helper svg {
    margin-bottom: -3px;
}

/* width */
#cast-carousel::-webkit-scrollbar {
    height: 20px;
}
/* Track */
#cast-carousel::-webkit-scrollbar-track {
    /* background: linear-gradient(0deg, var(--bg) 0%, var(--bg) 40%, var(--main) 40%, var(--main) 60%, var(--bg) 60%, var(--bg) 100%); */
    background: #909090;
}
/* Handle */
#cast-carousel::-webkit-scrollbar-thumb {
    background: #b73f00;
}
/* Handle on hover */
#cast-carousel::-webkit-scrollbar-thumb:hover {
    background: white;
}

@media (max-width: 700px) {
    .spotlight-inner, #spotlight {
        flex-direction: column !important;
    }
    .headshot {
        margin-bottom: 20px;
    }
    #handle-helper {
        top: -33px;
    }
}

@keyframes fade-in {
    from { opacity: 0 }
    to{ opacity: 1 }
}

@keyframes breath {
    from { color: var(--main) }
    to{ color: white }
}