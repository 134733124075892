#hero-container {
  height: 94%;
  overflow: hidden;
  position: relative;
}
#hero-background {
  position: absolute;
  height: 100%;
  width: 100%;
}
#hero-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
}
#hero-top img {
  z-index: 9;
  max-height: 250px;
  padding-right: 40px;
}
#hero-video {
  /* filter: grayscale(1) brightness(0.5); */
  height: 100%;
  width: 100%;
  object-fit: cover;
}
#hero-foreground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  line-height: 0.7;
  text-align: center;
}
#hero-foreground h1 {
  margin: -20px 0 0 0;
  font-size: 6em;
  color: var(--main);
  position: relative;
  z-index: 2;
  user-select: none;
  letter-spacing: 0.3rem;
}
#hero-foreground h2 {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  margin-top: 0;
}
#hero-foreground p {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
#watch img {
  padding: 20px;
  max-height: 80px;
}
#tagline {
  margin-top: 30px !important;
  margin-bottom: 0;
  font-size: 32px;
  z-index: 1;
  font-weight: 100;
}
#hero-items {
  text-transform: uppercase;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-top: 80px;
  list-style: none;
  justify-content: space-evenly;
  font-weight: 600;
  color: var(--main);
  font-size: 1.7em;
  z-index: 2;
}
#video-container {
  margin-top: 20px;
  z-index: 1;
  width: 60%;
  max-width: 800px;
  min-width: 320px;
}
#trailer-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Mulish";
  font-weight: 1000;
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--main);
  border: none;
  color: var(--bg);
  z-index: 1;
}
#trailer-link svg {
  margin-left: 10px;
}
#trailer-link:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 10px var(--main));
}
#trailer-lightbox {
  display: none;
  position: fixed;
  z-index: 99;
  background-color: #000c;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#trailer-lightbox button {
  background: none;
  border: none;
  color: var(--main);
  margin-bottom: 20px;
}
#trailer-lightbox svg {
  font-size: 3rem;
}
#trailer-lightbox svg:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 10px var(--main));
}
#video-container iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  max-width: 1200px;
}

@media screen and (max-width: 1280px) {
  body {
    font-size: 14px;
  }
  #hero-items {
    flex-direction: column;
    padding: 0;
  }
  #hero-items li {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 700px) {
  #hero-container {
    height: 80%;
    min-height: 680px;
  }
  #hero-foreground h1 {
    font-size: 60px;
  }
  #hero-foreground h2 {
    font-size: 2rem;
  }
  #tagline {
    margin-top: 15px;
    font-size: 20px !important;
  }
  #hero-top {
    flex-direction: column;
  }
  #hero-top img {
    padding-right: 0px;
    margin-bottom: 60px !important;
  }
  #hero-top div {
    margin-bottom: 20px;
  }
}