/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;1000&display=swap'); */

:root {
  --bg: #0b0e05;
  --main: #f3e6c2;
  --nav-height: 60px;
}
html, body {
  height: 100%;
  background: var(--bg);
  color: var(--main);
  overflow-y: auto;
  overflow-x: hidden;
}
body {
  font-family: 'Mulish';
  font-weight: '1000';
  margin: 0;
  display: flex;
  flex-direction: column;
}
p {
  font-family: 'Mulish';
  font-weight: '400';
  line-height: 1.2;
}
#root {
  min-height: 100%;
}
#main{
  height: calc(100% - var(--nav-height));
  display: contents;
}
a {
    text-decoration: none;
    color: var(--main);
}
a:hover {
  cursor: pointer;
  color: #fff;
  filter: drop-shadow(0px 0px 3px var(--main));
}