body {
    background: #0000;
}
#interviews {
    max-width: 800px;
    margin: auto;
    padding-top: 40px;
    position: relative;
    z-index: 1;
    background: #0000;
}
#interview-wrapper {
    width: 100%;
}
#interviews #synopsis {
    margin-bottom: 0;
    background-color: #0008 !important;
}
#interview-wrapper #synopsis {
    padding: 0;
}
#interview-wrapper #synopsis p {
    display: none;
}
#interviews #synopsis h1 {
    padding: 20px 10px;
    margin-bottom: 0;
}
#interview-background {
    /* filter: grayscale(1) brightness(0.5); */
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: fixed;
    top: 0;
    z-index: -1;
}
#interview-video {
    background-color: #333;
    aspect-ratio: 16/9;
    width: 100%;
}
#interview-selector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    justify-content: space-evenly;
    margin-bottom: 80px;
    background-color: #0008;
}
#interview-selector button {
    font-family: "Mulish";
    font-size: 1.2rem;
    font-weight: 1000;
    text-align: center;
    line-height: 1;
    max-width: 260px;
    padding: 10px;
    box-sizing: border-box;
    background: none;
    border: none;
    color: var(--main);
    display: flex;
    flex-direction: column;
    align-items: center;
    filter: drop-shadow(0 0 0 var(--main)) contrast(1);
    transition: 0.2s;
}
#interview-selector img {
    background-color: #333;
    pointer-events: none;
    max-width: 240px;
    margin-bottom: 10px;
    aspect-ratio: 16/9;
}
#interview-selector svg {
    min-width: 20px;
    min-height: 20px;
}
#interview-selector p {
    max-width: 240px;
    margin: 0;
}
#interview-selector button:hover, .active-interview {
    /* background-color: var(--main) !important;
    color: var(--bg) !important; */
    filter: drop-shadow(0 0 20px var(--main)) contrast(1.3);
}
@media (max-width: 700px) {
    #interview-wrapper #synopsis {
        padding: 0 10px;
    }
    #interview-selector button {
        font-size: 1rem;
        width: 50%;
        padding: 5px 0;
    }
    #interview-selector button:nth-child(odd) {
        padding-left: 10px;
        padding-right: 5px;
    }
    #interview-selector button:nth-child(even) {
        padding-left: 5px;
        padding-right: 10px;
    }
    #interview-selector img {
        width: 100%;
    }
}