#footer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    border-top: 1px solid;
    background-color: var(--bg);
}
.footer-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}
#footer img {
    max-height: 120px;
    max-width: 156px;
    margin: 0 20px;
}
.footer-section svg {
  font-size: 36px;
  margin: 0 10px;
}

@media screen and (max-width: 900px) {
    #footer {
      flex-direction: column;
      padding: 20px 40px;
    }
    .footer-section {
      margin-bottom: 20px;
    }
}