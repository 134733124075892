#no-match {
    display: flex;
    flex-direction: column;
    min-height: 76.4%;
    text-align: center;
    justify-content: center;
}
#no-match h2 {
    margin-top: 0;
    font-size: 2.3rem;
}
#no-match p {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#no-match a {
    font-weight: 600;
    background-color: var(--main);
    color: var(--bg);
    padding: 10px 20px;
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
}
#no-match svg {
    font-size: 24px;
    margin-left: 10px;
}