#episode-title {
    text-align: center;
    font-size: 3rem;
}
#episode-selector {
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: auto;
}
#episode-selector button, #episode-selector-dropdown button {
    font-family: "Mulish";
    font-weight: 1000;
    font-size: 1.4em;
    padding: 10px;
    width: 100%;
    background-color: #0008;
    border: 1px solid var(--main);
    color: var(--main);
}
#episode-selector button:hover, #episode-selector .active-episode {
    opacity: 1;
    background-color: var(--main);
    color: var(--bg);
}
#episode-selector-mobile {
    display: none;
    position: relative;
    background-color: var(--main);
    font-size: 1.5rem;
    font-weight: 900;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}
#current-selector {
    font-family: "Mulish";
    font-weight: 1000;
    font-size: 1.6rem;
    line-height: 1.5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--main);
    border: none;
    padding: 5px 20px;
    color: var(--bg) !important;
}
#episode-selector-mobile svg{
    height: 1.2rem;
}
#episode-container {
    color: var(--main);
    padding: 0 40px 40px 40px;
    max-width: 800px;
    margin: auto;
}
#episode-selector-dropdown a, #episode-selector-dropdown button {
    width: 100%;
    margin: 0;
    padding: 5px 0;
}
#episode-selector-dropdown {
    max-height: 0;
    top: 45px;
    overflow: hidden;
    position: absolute;
    z-index: 99;
    background-color: #333;
    transition: 1s ease-in-out;
}
.active-dropdown {
    max-height: 500px !important; 
}
#episode-selector-dropdown button {
    opacity: 0.8;
    font-size: 1.5rem;
}
#trailer-container {
    width: 100%;
    aspect-ratio: 16/9;
}
#synopsis h1 {
    margin-top: 0;
    line-height: 1;
}
#synopsis p {
    font-size: 0.9rem;
    text-align: justify;
}
#synopsis {
    margin-bottom: 40px;
    padding: 20px;
    background-color: #000000ad;
}

@media screen and (max-width: 700px) {
    #episode-title {
        font-size: 2rem;
    }
    #episode-selector {
        display: none;
    }
    #episode-selector-mobile {
        display: block;
    }
    #episode-container {
        padding: 0;
    }
    #synopsis {
        padding: 0 20px 40px 20px;
    }
}