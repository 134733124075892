nav {
  text-transform: uppercase;
  height: var(--nav-height);
  width: 100%;
  box-sizing: border-box;
  background-color: var(--bg);
  color: var(--main);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid var(--main);
}
nav svg {
  display: none;
  font-size: 2rem;
}
#nav-logo {
  margin: 0;
  letter-spacing: 1px;
}
#nav-items {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  justify-content: flex-end;
  font-weight: 600;
}
#mobile-menu {
  max-height: 0;
  overflow: hidden;
  transition: 0.5s;
  position: absolute;
  z-index: 99;
  width: 100%;
  background-color: var(--main);
}
.menu-active {
  max-height: 350px !important;
}
#mobile-nav-items {
  font-size: 2em;
  display: flex;
  flex-direction: column;
  list-style: none;
  font-weight: 600;
  padding: 20px;
  margin: 0;
}
#mobile-nav-items a {
  color: var(--bg);
}
#mobile-nav-items li {
  min-height: 60px;
}
#nav-items li {
  margin-left: 35px;
}

@media screen and (max-width: 700px) {
  #nav-items {
    display: none !important;
  }
  nav svg {
    display: block;
  }
  #polaroid-wrapper {
    flex-direction: column;
    margin-top: 60px
  }
}